import { Link } from "gatsby"
import styled from "styled-components"

export const Button = styled.button`
  font-family: "Montserrat";
  background: ${({ primary }) => (primary ? "#FFC403" : "#0B75FF")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "16px 40px" : "10px 32px")};
  color: ${({ primary }) => (primary ? "#060f52" : "#fff")};
  font-size: ${({ big }) => (big ? "20px" : "16px")};
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: ${({ round }) => (round ? "30px" : "none")};

  &:hover {
    background: ${({ primary }) => (primary ? "#0B75FF" : "#FFC403")};
    color: white;
  }
`

export const ButtonLink = styled(Link)`
  font-family: "Montserrat";
  background: ${({ primary }) => (primary ? "#FFC403" : "#0B75FF")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "16px 40px" : "10px 32px")};
  color: ${({ primary }) => (primary ? "#060f52" : "#fff")};
  font-size: ${({ big }) => (big ? "20px" : "16px")};
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: ${({ round }) => (round ? "30px" : "none")};

  &:hover {
    background: ${({ primary }) => (primary ? "#0B75FF" : "#FFC403")};
    color: white;
  }
`
