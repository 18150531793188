import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { MdOutlineLocationOn } from "react-icons/md"
import {
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineGlobal,
  AiOutlineFacebook,
} from "react-icons/ai"

const Footer = ({ displayImage }) => {
  return (
    <FooterContainer className={displayImage ? "top-image" : ""}>
      {displayImage ? (
        <StaticImage
          className="students-image"
          src="../assets/images/footer/students.png"
          alt="Asesorias CAMI"
          layout="constrained"
          placeholder="tracedSVG"
          height={500}
        />
      ) : null}
      <FooterContent>
        <FooterLeft>
          <StaticImage
            className="logo"
            src="../assets/images/logo.png"
            alt="Asesorias CAMI"
            layout="fixed"
            placeholder="tracedSVG"
            height={50}
          />
          <SocialLink
            href="https://www.facebook.com/Asesorias-CAMI-1296635490464865"
            target="_blank"
          >
            <AiOutlineFacebook
              css={`
                font-size: 1.5rem;
              `}
            />
          </SocialLink>
        </FooterLeft>
        <FooterRight>
          <ul>
            <li>
              <MdOutlineLocationOn
                css={`
                  font-size: 1.5rem;
                `}
              />
              <span>
                Av Las Palmas 118, Paseo de Las Palmas III, 66635 Apodaca, N.L.
              </span>
            </li>
            <li>
              <AiOutlinePhone
                css={`
                  font-size: 1.25rem;
                `}
              />
              <span>
                T. <a href="tel:8117469882">(81)1746.9882</a>{" "}
              </span>
            </li>
            <li>
              <AiOutlineMail
                css={`
                  font-size: 1.25rem;
                `}
              />
              <span>
                E.{" "}
                <a href="mailto:asesoriascami@gmail.com">
                  asesoriascami@gmail.com
                </a>
              </span>
            </li>
            <li>
              <AiOutlineGlobal
                css={`
                  font-size: 1.25rem;
                `}
              />
              <span>W. https://www.asesoriascami.com</span>
            </li>
          </ul>
        </FooterRight>
      </FooterContent>
      <FooterCopyright>
        © Todos los derechos reservados. Asesorias CAMI{" "}
        {new Date().getFullYear()}
      </FooterCopyright>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.div`
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #1a2050;
  position: relative;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;

  &.top-image {
    margin-top: 325px;
  }

  .students-image {
    position: absolute;
    top: -500px;
    left: calc((100vw - 750px) / 2);
  }
`

const FooterContent = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  flex-direction: column;
  padding: 0 35px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

const FooterCopyright = styled.div`
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  font-weight: normal;
  font-style: normal;
`

const FooterLeft = styled.div`
  .logo {
    margin-bottom: 25px;
  }
`

const FooterRight = styled.div`
  margin-top: 3rem;

  @media screen and (min-width: 1024px) {
    margin-left: 70px;
    margin-top: 0;
  }

  ul {
    list-style-type: none;
    margin-bottom: 20px;

    li {
      display: flex;
      margin-bottom: 5px;

      span {
        margin-left: 10px;
      }

      a {
        color: white;
      }
    }
  }
`

const SocialLink = styled.a`
  color: white;

  &:hover {
    color: #ccc;
  }
`
