import * as React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import { Button } from "./shared/button"

const Hero = ({ isHome, title, subtitle, type }) => {
  const data = useStaticQuery(
    graphql`
      query HeroQuery {
        allFile(filter: { relativeDirectory: { eq: "hero/background" } }) {
          edges {
            node {
              id
              name
              childImageSharp {
                gatsbyImageData(
                  transformOptions: { fit: COVER }
                  webpOptions: { quality: 50 }
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `
  )

  function getBackground(type) {
    const { node } = data.allFile.edges.find(
      ({ node }) => node.name.toUpperCase() === type
    )

    return <HeroBackground image={getImage(node)} alt={node.name} />
  }

  return (
    <HeroContainer id="inicio" className={isHome ? "" : "is-category"}>
      {getBackground(type)}
      <HeroContent>
        <HeroTitle>{title}</HeroTitle>
        <HeroSubtitle>{subtitle}</HeroSubtitle>
        {isHome ? (
          <>
            <HeroButton primary round onClick={() => scrollTo("#contacto")}>
              Más información
            </HeroButton>
            <HeroContentImage>
              <StaticImage
                className="content-image"
                src="../assets/images/hero/content.png"
                alt="Asesorias CAMI"
                layout="constrained"
                placeholder="blurred"
              />
            </HeroContentImage>
          </>
        ) : null}
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.section`
  font-family: Montserrat;
  display: flex;
  height: 80vh;
  width: 100vw;
  margin-top: -145px;
  padding-top: 160px;
  position: relative;
  justify-content: center;

  &.is-category {
    height: 50vh;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #060f52;
    opacity: 0.7;
  }
`

const HeroBackground = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const HeroContent = styled.div`
  width: 1200px;
  max-width: 1200px;
  padding-top: 80px;
  z-index: 1;
  position: relative;
  padding-left: 35px;

  @media screen and (min-width: 1200px) {
    padding-left: 0;
  }

  .content-image {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;

    & > div {
      height: 100%;
    }
  }
`

const HeroTitle = styled.h2`
  width: 50%;
  font-size: 80px;
  color: white;
  font-weight: 600;
  font-size: clamp(1.5rem, 6vh, 4rem);
`

const HeroSubtitle = styled.h3`
  font-size: 90px;
  color: #ffc403;
  font-weight: bold;
  font-style: italic;
  font-size: clamp(1.7rem, 6vh, 4.5rem);
`

const HeroButton = styled(Button)`
  font-size: clamp(0.8rem, 3vh, 1.8rem);
  margin-top: 30px;
`

const HeroContentImage = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  top: 0;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`
